import { useEffect } from 'react';
import LoaderComponent from '../FullScreenLoader/components/LoaderComponent';
import { useStyles } from './styles';

const RenderingLoader = () => {
	const { classes } = useStyles();

	useEffect( () => {
		const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
			event.preventDefault();
		};

		window.addEventListener( 'beforeunload', handleBeforeUnload );

		return () => {
			window.removeEventListener( 'beforeunload', handleBeforeUnload );
		};
	}, [] );

	return (
		<>
			<LoaderComponent />
			<p className={classes.renderingText}>
				Your video is rendering...
				<br />
				<span className={classes.doNotCloseText}>Don&apos;t close this tab!</span>
			</p>
		</>
	);
};

export default RenderingLoader;
